#project_container
{
    flex-direction: column;

    #current_project_header
    {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px;

        /* Inside Auto Layout */

        flex: 1;
        align-self: stretch;

        border-bottom: solid 1px #000;

        #home_container &
        {
            margin-bottom: 5px;
        }

        @media (max-width: $breakpoint-phone) {
            flex-direction: column;

            h2
            {
                max-width: none;
            }
        }
    }

    #mobile_project_blurb
    {
        margin-top: 5px;
        display: none;

        p
        {
            margin-bottom: 0px;

            a
            {
                color: #000;
                text-decoration: none;
            }
        }

        @media (max-width: $breakpoint-miniphone) {
            display: block;
        }
    }

    #project_content
    {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        width: 100%;

        @media (max-width: $breakpoint-phone) {
            flex-direction: column-reverse;
            margin-top: 5px;
        }
    
        #project_sidebar
        {
            display: flex;
            flex-direction: column;

            width: 232px;
            margin-right: 40px;

            @media (max-width: $breakpoint-phone) {
                width: 100%;
            }
        }

        #project_assets
        {
            flex: 1;

            .embed_container
            {
                width: 100%;
                margin-bottom: 20px;

                img
                {
                    width: 100%;
                }

                &.video_container
                {
                    position: relative;
                    padding-bottom: 56.25%; /* 16:9 */
                    height: 0;

                    iframe
                    {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

    }

    nav#site_projects
    {
        .project_header
        {
            flex-direction: column;

            h2
            {
                width: 100%;
                text-align: left;
            }

        }
    }
}

div#current_project_header
{
    /* Auto Layout */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0px;

    
    /* Inside Auto Layout */

    flex: none;
    align-self: stretch;
    flex-grow: 0;

    border-bottom: solid 1px #000;

    h2
    {
        
        font-style: normal;
        font-weight: bold;
        max-width: 50%;
        
        margin: 0px;

        a
        {
            text-decoration: none;
            color: inherit;
        }

        &#current_project_name
        {
            color: #000000;
        }

        &#current_project_date
        {
            color: #888888;
            text-align: right;
        }
    }
}