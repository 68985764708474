body
{
    background-image: URL('/assets/img/global/bg.svg');
    background-repeat: repeat-y;
    background-attachment: fixed;
    background-position: 0 -200px;

    font-family: Helvetica Neue, Helvetica, sans-serif;

    margin: 0;
    padding: 0;

    @media (max-width: $breakpoint-phone) {
        background-position: right -200px;
    }
}

header
{
    //background-color: pink;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    max-width: 1020px;
    margin: 5px 80px;

    a{
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 49px;

        color: #000000;

        span
        {
            animation: colorTransition 60s normal infinite;
        }

        
        text-decoration: none;

        &:visited
        {
            color: #000;
        }
    }

    #header_social
    {
        text-align: right;
        width: 100%;
        align-self: flex-end;
        padding-bottom: 7px;

        i
        {
            font-size: 20px;

            @media(max-width: $breakpoint-phone){
                font-size: 15px;
            }
        }
    }

    @media (max-width: $breakpoint-tablet) {
        margin: 5px 15px;
    }


    @media (max-width: $breakpoint-phone) {
        &.index\.markdown
        {
            display: none;
        }
        margin: 5px 15px 0px;
    }
    
}

#home_container, #project_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 20px 80px;

    max-width: 1020px;

    //background-color: green;

    @media (max-width: $breakpoint-tablet) {
        margin: 10px 15px;
    }

    @media (max-width: $breakpoint-phone) {
        flex-direction: column;
        margin: 0px 15px;
        max-width: none;
    }
}

#event_container
{
    max-width: 1020px;
    margin: 20px 80px;
}






nav#site_projects
{
    /* Auto Layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    flex: 1;
    margin: 40px 0px;

    input
    {
        margin: 12px 0px 40px;
        width: 100%;
    }

    ul
    {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;

        li
        {
            /* Auto Layout */

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;


            /* Inside Auto Layout */

            flex: none;
            align-self: stretch;
            flex-grow: 0;
            margin-bottom: 40px ;

            .project_tags
            {
                display: none;
            }

            .project_header
            {
                display: flex;
                flex-direction: row;
                align-self: stretch;
                width: 100%;
                border-bottom: 1px solid #000;
                margin-bottom: 5px;
            }

            .project_type
            {
                width: 100%;
                font-size: 14px;
                font-weight: normal;
                color: grey;
            }

            h2
            {
                flex: 1;
                font-style: normal;
                font-weight: bold;
                
                margin: 0px;

                a
                {
                    text-decoration: none;
                    color: inherit;
                }

                &.project_name
                {
                    color: #000000;
                    flex: 50%;

                    sup
                    {
                        vertical-align: top;
                    }

                    .fa-external-link-alt
                    {
                        font-size: 60%;
                        vertical-align: super;
                        color: magenta;
                        padding-left: 3px;
                    }
                }

                &.project_date
                {
                    color: #888888;
                    text-align: right;
                    margin-top: auto;
                    white-space: nowrap;
                }
            }

        }

        
    }
}

p
{
    font-size: 14px;
    line-height: 130%;

    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px 18.2px;
}

input, textarea
{
    background: #F5F5F5;
    border: none;
    min-height: 32px;
    padding: 0 5px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

@keyframes colorTransition {
  0%   { color: hsl(0, 100%, 50%); }
  1%   { color: hsl(3.6, 100%, 50%); }
  2%   { color: hsl(7.2, 100%, 50%); }
  3%   { color: hsl(10.8, 100%, 50%); }
  4%   { color: hsl(14.4, 100%, 50%); }
  5%   { color: hsl(18, 100%, 50%); }
  6%   { color: hsl(21.6, 100%, 50%); }
  7%   { color: hsl(25.2, 100%, 50%); }
  8%   { color: hsl(28.8, 100%, 50%); }
  9%   { color: hsl(32.4, 100%, 50%); }
  10%  { color: hsl(36, 100%, 50%); }
  11%  { color: hsl(39.6, 100%, 50%); }
  12%  { color: hsl(43.2, 100%, 50%); }
  13%  { color: hsl(46.8, 100%, 50%); }
  14%  { color: hsl(50.4, 100%, 50%); }
  15%  { color: hsl(54, 100%, 50%); }
  16%  { color: hsl(57.6, 100%, 50%); }
  17%  { color: hsl(61.2, 100%, 50%); }
  18%  { color: hsl(64.8, 100%, 50%); }
  19%  { color: hsl(68.4, 100%, 50%); }
  20%  { color: hsl(72, 100%, 50%); }
  21%  { color: hsl(75.6, 100%, 50%); }
  22%  { color: hsl(79.2, 100%, 50%); }
  23%  { color: hsl(82.8, 100%, 50%); }
  24%  { color: hsl(86.4, 100%, 50%); }
  25%  { color: hsl(90, 100%, 50%); }
  26%  { color: hsl(93.6, 100%, 50%); }
  27%  { color: hsl(97.2, 100%, 50%); }
  28%  { color: hsl(100.8, 100%, 50%); }
  29%  { color: hsl(104.4, 100%, 50%); }
  30%  { color: hsl(108, 100%, 50%); }
  31%  { color: hsl(111.6, 100%, 50%); }
  32%  { color: hsl(115.2, 100%, 50%); }
  33%  { color: hsl(118.8, 100%, 50%); }
  34%  { color: hsl(122.4, 100%, 50%); }
  35%  { color: hsl(126, 100%, 50%); }
  36%  { color: hsl(129.6, 100%, 50%); }
  37%  { color: hsl(133.2, 100%, 50%); }
  38%  { color: hsl(136.8, 100%, 50%); }
  39%  { color: hsl(140.4, 100%, 50%); }
  40%  { color: hsl(144, 100%, 50%); }
  41%  { color: hsl(147.6, 100%, 50%); }
  42%  { color: hsl(151.2, 100%, 50%); }
  43%  { color: hsl(154.8, 100%, 50%); }
  44%  { color: hsl(158.4, 100%, 50%); }
  45%  { color: hsl(162, 100%, 50%); }
  46%  { color: hsl(165.6, 100%, 50%); }
  47%  { color: hsl(169.2, 100%, 50%); }
  48%  { color: hsl(172.8, 100%, 50%); }
  49%  { color: hsl(176.4, 100%, 50%); }
  50%  { color: hsl(180, 100%, 50%); }
  51%  { color: hsl(183.6, 100%, 50%); }
  52%  { color: hsl(187.2, 100%, 50%); }
  53%  { color: hsl(190.8, 100%, 50%); }
  54%  { color: hsl(194.4, 100%, 50%); }
  55%  { color: hsl(198, 100%, 50%); }
  56%  { color: hsl(201.6, 100%, 50%); }
  57%  { color: hsl(205.2, 100%, 50%); }
  58%  { color: hsl(208.8, 100%, 50%); }
  59%  { color: hsl(212.4, 100%, 50%); }
  60%  { color: hsl(216, 100%, 50%); }
  61%  { color: hsl(219.6, 100%, 50%); }
  62%  { color: hsl(223.2, 100%, 50%); }
  63%  { color: hsl(226.8, 100%, 50%); }
  64%  { color: hsl(230.4, 100%, 50%); }
  65%  { color: hsl(234, 100%, 50%); }
  66%  { color: hsl(237.6, 100%, 50%); }
  67%  { color: hsl(241.2, 100%, 50%); }
  68%  { color: hsl(244.8, 100%, 50%); }
  69%  { color: hsl(248.4, 100%, 50%); }
  70%  { color: hsl(252, 100%, 50%); }
  71%  { color: hsl(255.6, 100%, 50%); }
  72%  { color: hsl(259.2, 100%, 50%); }
  73%  { color: hsl(262.8, 100%, 50%); }
  74%  { color: hsl(266.4, 100%, 50%); }
  75%  { color: hsl(270, 100%, 50%); }
  76%  { color: hsl(273.6, 100%, 50%); }
  77%  { color: hsl(277.2, 100%, 50%); }
  78%  { color: hsl(280.8, 100%, 50%); }
  79%  { color: hsl(284.4, 100%, 50%); }
  80%  { color: hsl(288, 100%, 50%); }
  81%  { color: hsl(291.6, 100%, 50%); }
  82%  { color: hsl(295.2, 100%, 50%); }
  83%  { color: hsl(298.8, 100%, 50%); }
  84%  { color: hsl(302.4, 100%, 50%); }
  85%  { color: hsl(306, 100%, 50%); }
  86%  { color: hsl(309.6, 100%, 50%); }
  87%  { color: hsl(313.2, 100%, 50%); }
  88%  { color: hsl(316.8, 100%, 50%); }
  89%  { color: hsl(320.4, 100%, 50%); }
  90%  { color: hsl(324, 100%, 50%); }
  91%  { color: hsl(327.6, 100%, 50%); }
  92%  { color: hsl(331.2, 100%, 50%); }
  93%  { color: hsl(334.8, 100%, 50%); }
  94%  { color: hsl(338.4, 100%, 50%); }
  95%  { color: hsl(342, 100%, 50%); }
  96%  { color: hsl(345.6, 100%, 50%); }
  97%  { color: hsl(349.2, 100%, 50%); }
  98%  { color: hsl(352.8, 100%, 50%); }
  99%  { color: hsl(356.4, 100%, 50%); }
  100% { color: hsl(360, 100%, 50%); }
}
