section#site_intro
{
    flex: 1;
    margin: 0px 40px 0px 0px;

    #toggle_bio
    {
        display: none;
    }

    @media (max-width: $breakpoint-phone) {

        margin: 0px;
        width: 100%;

        #bio
        {
            #toggle_bio
            {
                color: #000;
                display: block;
            }

            i.fa-minus-square
            {
                display: inline-block;
                right: 0px;
            }

            i.fa-plus-square
            {
                display: none;
                bottom: 1rem;
                right: 0px;
            }

            overflow: hidden;
            position: relative;

            &.closed
            {
                i.fa-plus-square
                {
                    display: inline-block;

                }

                i.fa-minus-square
                {
                    display: none;
                }

                p
                {
                    display: none;

                    &:first-of-type
                    {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;  
                        overflow: hidden;                        
                    }


                }             
                
            }
            p
            {
                padding-right: 20px;

                &:last-of-type
                {
                    margin-bottom: 0px;
                }
            }
            i
            {
                display: none;
                position: absolute;
                right: 15px;
                font-family: "Font Awesome 5 Free"; 
                font-weight: 900; 
                bottom: 0px;
            }
        }

    }


    h1
    {
        font-style: normal;
        font-weight: bold;
        
        @include clamp("font-size",12px,17vw,200px);

        color: #000000;


        /* Inside Auto Layout */

        flex: none;
        align-self: stretch;
        flex-grow: 0;
        margin: 0px 0px;

        span
        {
            animation: colorTransition 60s normal infinite;
        }

        
    }

    #contact_container{
        #contact_expand
        {
            display: none;
        }

        @media (max-width: $breakpoint-phone) {
            margin-top: 20px;

            #contact_expand
            {
                display: block;
            }
        }



        form#contact
        {
            /* Auto Layout */

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;

            
            width: 100%;


            @media (max-width: $breakpoint-phone) {
                display: none;

                &.show
                {
                    display: flex;
                }
            }

            /* Inside Auto Layout */

            flex: none;
            flex-grow: 0;
            margin: 0px 0px;

            fieldset
            {
                
                width: 100%;


                /* Inside Auto Layout */

                flex: none;
                align-self: stretch;
                flex-grow: 0;
                margin: 10px 0px 0px;

                
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 150.8%;

                border: none;

                padding: 0;

                input, textarea
                {
                    width: 100%;
                    margin-top: 5px;

                    &.error
                    {
                        border: solid 1px red;
                    }
                }

                textarea
                {
                    height: 195px;
                }

                input[type='radio']
                {
                    min-height: inherit;
                    width: auto;
                }

                div#name_container
                {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 0px;
                }

            }
        }
    }
}

#home_container
{
    nav h2#work_header
    {
        display: none;

        @media (max-width: $breakpoint-phone) {
            display: block;
        }
    }

    nav ul li 
    {
        @media (max-width: $breakpoint-tablet) {

            margin-bottom: 20px;

            .project_header {
        
                flex-direction: column;
                align-items: flex-start;

                h2{
                    max-width: 100%;   
                }
            }
        }

        max-width: 500px;
        
        h2
        {
            @include clamp("font-size",18px,2.5vw,30px);
        }
    }
    

    @media (max-width: $breakpoint-phone) {
        nav#site_projects
        {
            margin-top: 0px;
            width: 100%;

            h2
            {
                margin-bottom: 5px;
            }

            #project_filter
            {
                margin-top: 0px;
                margin-bottom: 10px;
            }
        }
    }
}