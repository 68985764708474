$fa-font-path: '/assets/webfonts';

@import 'fa/fontawesome';
@import "fa/solid";
@import "fa/brands";

@import 'helpers';
@import 'global';
@import 'home';
@import 'project';
@import 'portfolio';
@import 'event';