.portfolio
{

	section#portfolio_container
	{
	  display: grid;
	  grid-template-columns: minmax(700px, 1200px) 250px;
	  align-items: start;
	  justify-items: start;
	  position: relative;
	  margin: 0 auto;

	@media (max-width: $breakpoint-tablet) {
	        grid-template-columns: 1fr;
	    }
	}

	#portfolio_list
	{

	}

	ul#portfolio_subnav
	{
		@media (max-width: $breakpoint-tablet) {
	        display: none;
	    }


		position: sticky;
		top: 80px;

		font-size: 10pt;
		list-style: none;

		li
		{
			margin-bottom: 7px;
		}

	  	a
	  	{
	  		color: #707070;
	  		text-decoration: none;

	  		&:hover
	  		{
	  			text-decoration: underline;
	  		}
	  	}
	}

	h2#current_project_name, h2.current_project_date
	{
		font-size:1.3em;
	}

	h2#portfolio_title
	{
		font-size:1.7em;
		margin-left: 80px;

		@media (max-width: $breakpoint-tablet) {
			margin-left: 15px;
		}
	}
}