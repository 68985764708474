button.event_toggle
{
	font-size: 1em;
	border: none;
	background: none;
	padding: 0px;
	cursor: pointer;
}

.event_calendar
{
	overflow: hidden;
	height: 0px;

	&.open
	{
		height: 600px;
	}
}